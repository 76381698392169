import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { USER_HOME_PAGE_LINK } from 'src/constants'

const Dashboard = () => {
  const router = useRouter()

  useEffect(() => {
    router.push(USER_HOME_PAGE_LINK)
  })

  return <></>
}

export default Dashboard
